<template>
  <div class="news-detail">
    <my-header/>
    <h5>{{state.news.title}}</h5>
    <div class="news-detail_date">发布时间：{{state.news.publishTime}}</div>
    <div class="news-detail_content" v-html="state.news.content"></div>
  </div>
</template>
<script>
import { onMounted, reactive } from 'vue'
import { useRoute } from 'vue-router';
import MyHeader from '@/components/header/Header';
import { findNewsById } from '@/api/news';

export default {
  name: 'news-detail',
  components: {
    MyHeader
  },
  setup() {
    const route = useRoute();
    const state = reactive({
      news: {}
    })

    const getNewsData = async() => {
      const { id } = route.params;
      const { data } = await findNewsById(id);
      state.news = data;
    }

    onMounted(() => {
      getNewsData();
    });

    return {
      state
    }
  }
}

</script>

<style lang="less">
.news-detail {

  h5 {
    margin: 30px auto 20px;
    padding-bottom: 20px;
    width: 350px;
    text-align: center;
    border-bottom: dotted 1px #CCC;
  }

  .news-detail_date {
    padding: 10px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 14px;
  }

  .news-detail_content {
    padding: 0 15px;

    p {
      margin: 0;
      padding: 0;
      text-align: justify;

      span {
        font-size: 16px!important;
        line-height: 24px;
      }
    }

    img {
      width: auto;
    }
  }
}
</style>
